@media (max-width: 1400px) {
  .header-banner-img {
    max-width: 500px;
  }
  .contact-title {
    font-size: 150px;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
  h1 {
    font-size: 40px;
  }
  h3 {
    font-size: 25px;
  }
  h2 {
    font-size: 35px;
  }
  .header-banner-img {
    max-width: 320px;
  }
  .footer .footer__inner .footer__inner-logo {
    font-size: 70px;
    top: -75px;
  }
  .footer .footer__inner .footer__inner-con .footer__inner-info {
    p {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }
  .footer .footer__inner .footer__inner-con ul li a {
    font-size: 16px;
  }

  .header__inner-nav .nav-list > li + li {
    margin-left: 25px;
  }
  .services-items .services-item {
    padding: 10px;
  }
  .services-items .services-item-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .pricing-items {
    gap: 15px;
  }
  .pricing-item {
    padding: 15px;
  }
  .pricing-item-price {
    font-size: 40px;
  }
  .pricing-item-price span {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .pricing-item-title {
    font-size: 20px;
  }
  .pricing-item-text {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .banner {
    padding: 50px 0px;
  }
  .contact-box-info h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .contact-box-info-text {
    font-size: 20px;
  }
  .contact-box-info a {
    font-size: 20px;
  }
  .contact-title {
    font-size: 110px;
  }
  .head-text .head-text-subtext {
    font-size: 25px;
  }
  .order {
    background-position: right bottom 20px;
  }
  .request .head-text .head-text-subtext {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  h2 {
    font-size: 25px;
  }
  .header-banner-img {
    display: none;
  }
  .header__inner-nav {
    margin-left: 0px;
  }
  body {
    font-size: 14px;
  }
  .header__inner {
    min-height: 80px;
  }
  .header__inner .header__inner-logo a {
    width: 90px;
  }
  .header__menu-btn {
    display: block;
    z-index: 2;
  }
  .header__inner.active {
    .header__inner-link {
      margin-left: 0px;
      a svg path:first-child {
        fill: $white;
      }
      a svg path:last-child {
        stroke: $white;
      }
      a + a {
        margin-left: 35px;
      }
    }
  }
  .header__inner-nav .global-btn {
    display: block;
    width: 100%;
  }
  .header__inner-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $white;
    left: 0;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 0%;
    padding: 60px 20px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100%);
  }
  .header__inner-nav.active {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 2;

    .nav-list-item.drop-down {
      .nav-list-item-btn {
        color: $white;
        svg path {
          fill: $white;
        }
      }
    }
    .nav-list-item.drop-down.active {
      .nav-list-item-drop-down {
        position: static;
        background-color: transparent;
        display: flex;
        ul li a {
          text-align: left;
        }
      }
    }
    .nav-list {
      flex-direction: column;
      width: 100%;
      margin: auto 0px;
      align-items: flex-start;
      overflow-y: auto;
      > a {
        margin-left: 0px;
        margin-top: 15px;
      }
      .nav-list-item {
        a {
          width: 100%;

          display: block;
          text-align: center;
        }
      }
      .nav-list-item + .nav-list-item {
        margin-left: 0px;
        margin-top: 15px;
      }
    }

    .nav-list .nav-list-item .nav-list-item-drop-down ul li a {
      white-space: normal;
    }

    .nav-list-item-drop-down {
      max-width: 100%;
      transition: none !important;
      display: none;
    }
  }
  .footer .footer__inner .footer__inner-logo {
    font-size: 50px;
    top: -65px;
  }
  .header-banner {
    padding-top: 100px;
  }
  .btn-big {
    height: 45px;
  }
  .experience__inner {
    flex-direction: column;
  }
  .experience {
    padding: 50px 0px;
  }
  .experience-info p {
    margin-bottom: 25px;
  }
  .experience-info .experience-info-items {
    gap: 20px;
  }
  .global-head {
    flex-direction: column;
    gap: 20px;
  }
  .services {
    padding: 50px 0px;
  }
  .global-head-text .global-head-text-label {
    font-size: 20px;
  }
  .services__inner {
    gap: 30px;
  }
  .services-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .services-items .services-item p {
    max-width: 100%;
  }
  .pricing-items {
    grid-template-columns: repeat(1, 1fr);
  }
  .pricing__inner {
    gap: 40px;
  }
  .privacy__inner {
    gap: 25px;
  }
  .privacy {
    padding: 50px 0px;
  }
  .contact-box {
    flex-direction: column;
  }
  .contact-box-form {
    max-width: 100%;
  }
  .contact-title {
    font-size: 80px;
  }
  .contact {
    background-image: none !important;
    padding: 48px 0px 60px;
  }
  .head-text .head-text-subtext {
    font-size: 20px;
  }
  .head-text-con {
    padding: 40px 0px;
  }
  .order-form-title {
    font-size: 18px;
  }
  .order {
    padding: 50px 0px;
  }
  .request {
    padding: 100px 0px;
  }
  .request .head-text .head-text-subtext {
    font-size: 18px;
}
}
@media (max-width: 600px) {
  .head-text .head-text-subtext {
    font-size: 18px;
  }
  .banner {
    border-bottom: 60px solid #fff;
  }
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  .checkbox-item {
    padding-left: 18px;
  }
  .checkbox-item label::after {
    left: -18px;
  }
  .checkbox-item label::before {
    left: -18px;
  }
  .header-banner h1 {
    margin-bottom: 20px;
  }
  .footer .footer__inner .footer__inner-con {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .contact-title {
    font-size: 70px;
  }

  .contact-box-info h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .contact-box-info-text {
    font-size: 18px;
  }
  .contact-box-info a {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .order-form-box-items {
    gap: 40px;
  }
  .order-form-box-items .order-form-box-item {
    flex-direction: column;
    gap: 40px;
  }
  .contact-box-form-item.col-2 {
    flex-direction: column;
    gap: 40px;
  }
  .contact-title {
    font-size: 40px;
  }
  .services-items {
    grid-template-columns: repeat(1, 1fr);
  }
}
