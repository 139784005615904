@import 'vars';
@import 'reset';
@import 'global';
.header {
  background-color: $blue-bg;
  z-index: 1;
}
.header.header--home {
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;

  .header__inner-logo {
    position: relative;
    z-index: 3;
    a {
      display: flex;
    }
  }
}
.header__inner-nav {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 33px;
  .nav-list {
    display: flex;
    align-items: center;
    > li {
      & + li {
        margin-left: 40px;
      }
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.02em;
        color: #121212;
      }
      a:hover {
        color: $blue;
      }
      a.active {
        font-weight: 500;
        color: $blue;
      }
    }
    > a {
      margin-left: 48px;
    }
    .nav-list-item {
      position: relative;

      .nav-list-item-drop-down {
        visibility: hidden;
        z-index: 1;
        transform: translate(0px, 10%);
        opacity: 0;
        position: absolute;
        left: -15px;
        top: 40px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
        padding: 8px 0px;
        pointer-events: none;
        transition: all 0.3s ease;
        min-width: 150px;
        ul li {
          display: flex;
          a {
            padding: 5px 20px;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      &.drop-down.active {
        .nav-list-item-drop-down {
          visibility: visible;
          transform: translate(0px, 0px);
          opacity: 1;
          pointer-events: all;
        }
        .nav-list-item-btn svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .nav-list-item.drop-down .nav-list-item-btn {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 20px;
      cursor: pointer;

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 7px;
        transition: all 0.3s ease;
      }
    }
  }
}
.header__inner-link {
  margin-left: auto;
  display: flex;
  align-items: center;
  a {
    display: flex;
  }
  a + a {
    margin-left: 35px;
  }
}
.header-banner {
  background-color: $blue-bg;
  padding-bottom: 75px;
  padding-top: 272px;
  position: relative;
  .container {
    position: relative;
  }
  .header-banner__inner {
    display: flex;
    flex-direction: column;
    max-width: 596px;
    width: 100%;
  }
  .header-banner-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.06em;
    color: #4399fe;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  h1 {
    font-weight: 600;
    letter-spacing: -0.04em;
    color: #121212;
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 22px;
  }
}
.header-banner-img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.experience {
  padding-top: 105px;
  padding-bottom: 97px;
}
.experience__inner {
  display: flex;
  gap: 30px;
}
.experience-img {
  img {
  }
}
.experience-info {
  max-width: 482px;
  width: 100%;
  h3 {
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #282a2e;
    margin-bottom: 27px;
  }
  p {
    margin-bottom: 53px;
  }
  .experience-info-items {
    display: flex;
    flex-direction: column;
    gap: 36px;
    .experience-info-item {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

.global-head {
  display: flex;
  gap: 30px;
  h2 {
    max-width: 586px;
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #000;
  }
}
.global-head-text {
  max-width: 580px;
  width: 100%;
  .global-head-text-label {
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #fe7443;
    margin-bottom: 15px;
  }
  p {
    opacity: 0.7;
  }
}

.services {
  padding: 117px 0 125px;
  background-color: $blue-bg;
}
.services__inner {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.services-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  .services-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px 60px;
    transition: all 0.3s ease;
    &-icon {
      margin-bottom: 48px;
      svg path {
        transition: all 0.3s ease;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 25px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #343434;
      margin-bottom: 35px;
    }
    p {
      opacity: 0.7;
      max-width: 200px;
      margin: 0 auto;
    }
  }
  .services-item:hover {
    background-color: $white;
    box-shadow: 0 4px 90px 0 rgba(0, 0, 0, 0.15);
    .services-item-icon {
      svg path {
        fill: #118ac5;
      }
    }
  }
}
.pricing {
  padding: 50px 0px;
}
.pricing__inner {
  display: flex;
  flex-direction: column;
  gap: 90px;
  .global-head {
    h2 {
      color: #334047;
    }
  }
}
.pricing-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.pricing-item {
  padding: 36px 32px 42px;
  background-color: #fafafa;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  &-price {
    font-weight: 600;
    font-size: 54px;
    line-height: 100%;
    color: #2484b1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;

    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #5a717c;
      margin-bottom: 10px;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 26px;
    color: #334047;
    margin-bottom: 12px;
  }
  &-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
    color: #45565f;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    li {
      padding-left: 25px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #2484b1;
        border-radius: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  a {
    margin-top: 42px;
  }
}
.pricing-item.active {
  background-color: #cae8fd;
}
.banner {
  padding: 190px 0px 130px;
  border-bottom: 110px solid $white;
  background-color: $blue-bg;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.banner__inner {
  display: flex;
  flex-direction: column;
  gap: 25px;
  h2 {
    max-width: 585px;
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #334047;
  }
}
.privacy {
  padding-bottom: 75px;
  padding-top: 50px;
}
.privacy__inner {
  display: flex;
  flex-direction: column;
  gap: 45px;
  h2 {
    font-weight: 600;
    letter-spacing: -0.04em;
    color: #121212;
  }
  .privacy-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
      opacity: 0.7;
    }
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 162%;
      letter-spacing: 0.03em;
      color: #2f2f2f;
      opacity: 0.7;
    }
  }
}
.contact {
  padding: 48px 0px 225px;
  background-color: #80c1ef;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom;

  .container {
    max-width: 1275px;
  }
}
.contact__inner {
  display: flex;
  flex-direction: column;
}
.contact-title {
  font-weight: 700;
  font-size: 189px;
  line-height: 150%;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $white;
  color: transparent;
}
.contact-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  &-info {
    display: flex;
    flex-direction: column;
    max-width: 510px;
    width: 100%;
    h2 {
      font-weight: 700;
      font-size: 64px;
      color: $white;
    }
    &-text {
      font-size: 30px;
      line-height: 166%;
      color: $white;
      margin-bottom: 15px;
    }
    a {
      font-size: 30px;
      line-height: 166%;
      color: #fff;
    }
  }
}
.contact-box-form {
  max-width: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  &-item {
  }
  &-item.col-2 {
    display: flex;
    gap: 15px;
  }
  .g-input {
    color: #f2f2f2;
    border-color: #f2f2f2;
    &::placeholder {
      color: #f2f2f2;
    }
  }
  .g-select {
    color: #f2f2f2;
    border-color: #f2f2f2;
    &::placeholder {
      color: #f2f2f2;
    }
  }
}
.head-text-con {
  padding: 73px 0 53px;
}
.head-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2 {
    font-weight: 600;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    text-align: center;
    color: #121212;
  }
  .head-text-subtext {
    font-size: 32px;
  }
}
.order {
  padding: 86px 0 168px;
  background-color: $blue-bg;
  border-bottom: 110px solid $white;
  background-repeat: no-repeat;
  background-position: right top 20px;
  background-size: contain;
}
.order-form-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #0f0f0f;
  margin-bottom: 30px;
}

.order-form {
  display: flex;
  flex-direction: column;
}
.order-form-radio {
  margin-bottom: 42px;
}
.order-form-radio-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.order-form-textarea {
  margin-bottom: 32px;
  .g-input {
    max-width: 470px;
    &::placeholder {
      color: #575757;
      opacity: 0.26;
    }
  }
}
.order-form-box {
  display: flex;
  flex-direction: column;
}
.order-form-box-items {
  display: flex;
  flex-direction: column;
  max-width: 470px;
  width: 100%;
  gap: 50px;
  margin-bottom: 55px;
  .order-form-box-item {
    display: flex;
    gap: 15px;
  }
}

.request {
  background-color: #80c1ef;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 193px 0px 320px;
  .container {
    height: 100%;
  }
  .head-text {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    color: $white;
    gap: 0px;
    h2 {
      color: $white;
    }
    .head-text-subtext {
      font-weight: 300;
      font-size: 28px;
      line-height: 139%;
      text-align: center;
    }
  }
}

.footer {
  background-color: $blue-bg;
  padding: 36px 0px;
  .footer__inner {
    position: relative;
    .footer__inner-logo {
      position: absolute;
      left: 0;
      top: -95px;
      font-weight: 600;
      font-size: 96px;
      line-height: 100%;
      color: $blue;
    }
    .footer__inner-con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      ul {
        display: flex;
        flex-direction: column;
        li {
          a {
            font-weight: 300;
            font-size: 20px;
            line-height: 250%;
            color: #0f0f0f;
            white-space: nowrap;
          }
        }
      }
      .footer__inner-info {
        max-width: 787px;
        width: 100%;
        p {
          font-weight: 300;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #2f2f2f;
        }
        a {
          font-weight: 300;
          font-size: 20px;
          line-height: 250%;
          color: #0f0f0f;
        }
      }
    }
  }
}
@import 'media';
