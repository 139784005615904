@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap');
body.hiden {
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  background: $white;
  color: $black;
}

h1 {
  font-size: 55px;
  line-height: 118%;
}

h2 {
  font-size: 54px;
  line-height: 119%;
}
h3 {
  font-size: 36px;
  line-height: 128%;
}
p {
  font-size: 16px;
  line-height: 162%;
  letter-spacing: 0.03em;
  color: #2f2f2f;
}

.header__inner-link.header__inner-link--home {
  .btn {
    background-color: $white;
  }
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  flex-grow: 1;
}
.container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.header__menu-btn {
  display: none;
  margin-left: auto;
  width: 18px;
  height: 12px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.header__menu-btn span {
  display: block;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
  width: 100%;
  background: $black;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2),
.header__menu-btn span:nth-child(3) {
  top: 5px;
  width: 100%;
}

.header__menu-btn span:nth-child(4) {
  top: 10px;
  width: 100%;
  right: 0;
  left: inherit;
}
.header__menu-btn.open span {
  border-radius: 10px;
}
.header__menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.header__menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.btn {
  width: fit-content;
  border-radius: 10px;
  background: $orange;
  color: #404040;
  padding: 12px 20px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: 1px solid transparent;
}

.btn:hover {
  background: $white;
  border-color: $orange;
}

.btn-small {
  width: fit-content;
  border-radius: 10px;
  background: $orange;
  color: #404040;
  padding: 12px 20px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: 1px solid transparent;
}

.btn-small:hover {
  background: $white;
  border-color: $orange;
}

.btn-big {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 30px;
  background: $orange;
  color: #404040;
  padding: 0px 63px;
  transition: all 0.3s ease;
  font-weight: 600;
  height: 70px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: 1px solid transparent;
}

.btn-big:hover {
  background: $white;
  border-color: $orange;
}

.form-input-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.done {
    label {
      color: $black;
    }
  }
  label {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  input {
    width: 100%;
    padding: 0px 5px;
    height: 35px;
    border-bottom: 1px solid $black;
    color: $black;
  }
}
.form-input-item:hover {
  input {
    border-bottom: 1px solid $black;
  }
  label {
    color: $black;
  }
}

.form-input-textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.done {
    label {
      color: $black;
    }
  }
  label {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  textarea {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid $black;
    color: $black;
    outline: none;
    resize: none;
  }
}

.form-input-textarea:hover {
  textarea {
    border-bottom: 1px solid $black;
  }
  label {
    color: $black;
  }
}

.checkbox-item {
  padding-left: 27px;
  display: flex;
  align-items: center;
  .checkbox-radio {
    display: none;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_771)'%3E%3Cpath d='M5.80556 10.5388L6.20153 10.8748L6.52229 10.4664L10.2646 5.70152L10.5679 5.31529L10.1872 5.00507L9.51117 4.45426L9.11661 4.13277L8.80217 4.53297L5.9319 8.18606L4.06752 6.60389L3.67938 6.2745L3.35702 6.66849L2.80235 7.34642L2.49136 7.72652L2.86583 8.04428L5.80556 10.5388ZM0.5 7.32408C0.5 5.69792 1.13433 4.14018 2.26059 2.99306C3.38654 1.84626 4.91169 1.2037 6.5 1.2037C8.08831 1.2037 9.61346 1.84626 10.7394 2.99306C11.8657 4.14018 12.5 5.69792 12.5 7.32408C12.5 8.95023 11.8657 10.508 10.7394 11.6551C9.61346 12.8019 8.08831 13.4444 6.5 13.4444C4.91169 13.4444 3.38654 12.8019 2.26059 11.6551C1.13433 10.508 0.5 8.95023 0.5 7.32408Z' fill='black' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_771'%3E%3Crect width='13' height='13.2407' fill='white' transform='translate(0 0.703705)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      border-radius: 50%;
      left: -27px;
      top: 3px;
      transform: scale(0) rotateZ(180deg);
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: #e0e0e0;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      left: -27px;
      top: 3px;
    }
  }
}

.checkbox-radio:checked + label::before {
  transform: scale(1) rotateZ(0deg);
}

.g-input {
  width: 100%;
  padding-right: 10px;
  border-bottom: 1px solid #0f0f0f;
  font-size: 20px;
  line-height: 100%;
  color: #0f0f0f;
  &::placeholder {
    color: #0f0f0f;
  }
}
.g-select {
  width: 100%;
  padding-right: 10px;
  border-bottom: 1px solid #0f0f0f;
  font-size: 20px;
  line-height: 100%;
  color: #0f0f0f;
  option {
    background-color: rgb(142, 142, 142);
  }
  &::placeholder {
    color: #0f0f0f;
  }
}

.g-textarea {
  width: 100%;
  padding-right: 10px;
  border-bottom: 1px solid #0f0f0f;
  font-size: 20px;
  resize: none;
  line-height: 250%;
  color: #0f0f0f;
  outline: none;
  height: 38px;
  overflow: hidden;
  &::placeholder {
    color: #0f0f0f;
  }
}

.radiobutton-input {
  visibility: hidden;
  width: 0.01px;
  height: 0.01px;
  overflow: hidden;
  z-index: -10;
}
.radiobutton-label {
  display: flex;
  width: 100%;
}
.custom-radiobutton {
  height: 19px;
  width: 19px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #acacac;
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 9px;
    height: 9px;
    top: 3px;
    left: 3px;
    background-color: transparent;
    border-radius: 50%;
  }
}
.radiobutton-label-con {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  font-size: 20px;
  line-height: 100%;
  color: #0f0f0f;
}
.radiobutton-input:checked + .radiobutton-label-con .custom-radiobutton::after {
  background-color: #80c1ef;
}
.radiobutton-input:checked + .radiobutton-label-con .custom-radiobutton {
  border-color: #80c1ef;
}

.radiobutton-label:checked {
  background-color: red;
}
